import 'jquery-ujs'
import '../backoffice/vendors/custom/fullcalendar/fullcalendar.bundle.css'
import '../backoffice/vendors/base/vendors.bundle.css'
import '../backoffice/vendors/base/style.bundle.css'
import '../src/stylesheets/backoffice/index.scss'

import 'script-loader!babel-loader!../backoffice/vendors/webfont'
import 'script-loader!babel-loader!../backoffice/vendors/base/vendors.bundle.js'

/**
 * Summernote i18n support:
 * default - 'en-US' already included,
 * other languages add here
 */
// import 'script-loader!babel-loader!../backoffice/vendors/base/summernote_lang/summernote-fr-FR'

import 'script-loader!babel-loader!../backoffice/vendors/base/scripts.bundle.js'
import 'script-loader!babel-loader!../backoffice/vendors/custom/jquery-sortable/jquery-sortable.js'
import 'script-loader!babel-loader!../backoffice/vendors/custom/jquery-ui/jquery-ui.bundle.js'
import 'script-loader!babel-loader!../backoffice/vendors/custom/summernote-addclass/summernote-header-addclass'
import 'script-loader!babel-loader!../backoffice/vendors/custom/summernote-addclass/summernote-paragpraph-addclass'
import 'script-loader!babel-loader!../backoffice/vendors/custom/summernote-addclass/summernote-buttons-addclass'

import 'script-loader!babel-loader!../backoffice/vendors/custom/fullcalendar/fullcalendar.bundle.js'
import 'script-loader!babel-loader!../backoffice/vendors/custom/clipboard_polyfill/clipboard_polyfill'
import 'script-loader!babel-loader!../backoffice/app/js/dashboard.js'
import 'script-loader!babel-loader!../backoffice/app/js/deposits.js'
import 'script-loader!babel-loader!../backoffice/snippets/pages/user/login.js'
import 'script-loader!babel-loader!../backoffice/vendors/custom/cocoon/cocoon.js'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/redactor.min'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/alignment/alignment.min'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/counter/counter.min'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/fontcolor/fontcolor.min'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/fullscreen/fullscreen.min'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/imagemanager/imagemanager.min'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/video/video.min'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/headerclass/headerclass'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/textsize/textsize'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/textweight/textweight'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/letterspacing/letterspacing'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/lineheight/lineheight'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/buttonclass/buttonclass'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/line/line'
import 'script-loader!babel-loader!../backoffice/vendors/custom/redactor-3_3_5/redactor/_plugins/tweet/tweet'

// components
import 'script-loader!babel-loader!../backoffice/app/js/components/datepickers'
import 'script-loader!babel-loader!../backoffice/app/js/components/dropzone'
import 'script-loader!babel-loader!../backoffice/app/js/components/inputs'
import 'script-loader!babel-loader!../backoffice/app/js/components/phone-number-inputs'
import 'script-loader!babel-loader!../backoffice/app/js/components/portlet-draggable'
import 'script-loader!babel-loader!../backoffice/app/js/components/tooltip'
import 'script-loader!babel-loader!../backoffice/app/js/components/blur_switcher'
import 'script-loader!babel-loader!../backoffice/app/js/components/env_banner'
import 'script-loader!babel-loader!../backoffice/app/js/components/google_address_autocomplete'
import 'script-loader!babel-loader!../backoffice/app/js/components/tables'
import 'script-loader!babel-loader!../backoffice/app/js/components/download_label_button'

// utilities
import 'script-loader!babel-loader!../backoffice/app/js/utilities/change_focus_after_click'
import 'script-loader!babel-loader!../backoffice/app/js/utilities/close_colapse_without_data'
import 'script-loader!babel-loader!../backoffice/app/js/utilities/ajax_form_modal'
import 'script-loader!babel-loader!../backoffice/app/js/utilities/ajax_response_handler'
import 'script-loader!babel-loader!../backoffice/app/js/utilities/scroll-to-top'
import 'script-loader!babel-loader!../backoffice/app/js/utilities/toggle_required_field'
import 'script-loader!babel-loader!../backoffice/app/js/utilities/radio_buttons'
import 'script-loader!babel-loader!../backoffice/app/js/utilities/add_remove_fields'
import 'script-loader!babel-loader!../backoffice/app/js/utilities/change_premiums_on_select'
import 'script-loader!babel-loader!../backoffice/app/js/utilities/ajax_file_upload'
import 'script-loader!babel-loader!../backoffice/app/js/utilities/linked_form_validation.js'

// pages
import 'script-loader!babel-loader!../backoffice/app/js/pages/dashboard.js'
import 'script-loader!babel-loader!../backoffice/app/js/pages/edit_bank_account.js'
import 'script-loader!babel-loader!../backoffice/app/js/pages/bank_account_page.js'
import 'script-loader!babel-loader!../backoffice/app/js/pages/storage_fees_data_generators.js'
import 'script-loader!babel-loader!../backoffice/app/js/pages/countries.js'
import 'script-loader!babel-loader!../backoffice/app/js/pages/edit_admin_groups.js'
import 'script-loader!babel-loader!../backoffice/app/js/pages/discount.js'
import 'script-loader!babel-loader!../backoffice/app/js/pages/repeat_buy.js'
import 'script-loader!babel-loader!../backoffice/app/js/pages/authy_sing_in.js'
import 'script-loader!babel-loader!../backoffice/app/js/pages/premium_calculations.js'

import 'script-loader!babel-loader!../backoffice/app/js/redactor'
import 'script-loader!babel-loader!../backoffice/app/js/loader'
import 'script-loader!babel-loader!../backoffice/app/js/fonts'
import 'script-loader!babel-loader!../backoffice/app/js/selects'
import 'script-loader!babel-loader!../backoffice/app/js/sorting'

import 'script-loader!babel-loader!../backoffice/product_statistic'

import '../backoffice/app/media/img/bg/bg-3.jpg'
import '../backoffice/app/media/img/misc/user_profile_bg.jpg'
import '../backoffice/app/media/img/logo_main.svg'
import '../backoffice/app/media/img/social_en.png'
import '../backoffice/app/media/img/social_fr.png'
import '../backoffice/app/media/img/users/user-icon.jpg'

// require.context('../backoffice/app/media/img', true, /\.(gif|jpg|png|svg)$/i)

/**
 * Do not write any code directly here!
 * Cause modules imported via "script-loader" run after this module.
 * Workaround: import all scripts with "script-loader"
 */
